/** Adopted from the GatsbyJS SEO advanced tutorial */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const removeLocale = pathname => ['/en/', '/cn/', '/hk/'].includes(pathname.substr(0, 4)) ? pathname.substr(3) : pathname

const titleDict = {
  'hk': '伯恩光學',
  'cn': '伯恩光学',
  'en': 'BIEL Crystal',
}
function SEO({ description, title, image = `/img/apple-touch-icon.png`, pathname, key }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
          }
        }
      }
    `
  )

  const { i18n } = useTranslation()
  const metaDescription = description || site.siteMetadata.description
  const canonical = removeLocale(pathname) || null

  const metaTitle = titleDict[i18n.language || 'hk']

  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language || 'hk',
      }}
      title={title}
      titleTemplate={`%s | ${metaTitle}`}
      script={
        [
          {
            type:"text/javascript",
            src:"https://www.googletagmanager.com/gtag/js?id=G-9WW232MT3T",
            async:"async"
          },
          {
            type:"text/javascript",
            src:"/tag.js",
          }
        ]
      }
      link={
        [
          {
            rel: "canonical",
            href: canonical,
          },
          {
            rel: "icon",
            type: "image/png",
            href: `/img/apple-touch-icon.png`,
          },
          {
            rel: "apple-touch-icon", sizes: "180x180", href: "/img/apple-touch-icon.png"
          },
          {
            rel: "manifest",
            href: "/img/site.webmanifest",
          },
        ]
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "theme-color",
          content: "#fff"
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "og:image",
          content: image,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: 'cn',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO