import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import moment from "moment"
import { RollbackOutlined } from "@ant-design/icons"

import SEO from "@components/SEO"
import Layout from "@components/Layout"
import Content, { HTMLContent } from "@components/Content"
import { Divider } from "antd"
import LocalizedLink from "@components/LocalizedLink"

const NewsArticleCSS = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;

  min-height: 80vh;
  max-width: 70vw;
  margin: 0 auto;

  display: flex;
  justify-content: center;

  img {
    width: 70vw;
  }

  h1 {
    font-size: 3rem;
    font-weight: 800;
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 2rem;
    }
  }

  .date-row {
    display: flex;
    justify-content: space-between;
  }

  .article-date-published {
    color: #777;
    font-size: 1rem;
  }
`

export const NewsArticleTemplate = ({ title, tags, description, date, html, contentComponent, location, t, i18n }) => {
  const NewsArticleContent = contentComponent || Content

  const { i18n: { language } } = useTranslation()


  return (
    <NewsArticleCSS id="news-article" >
      <section>
        <h1>{title}</h1>
        <div className="date-row">
          <span className="article-date-published">{description} {moment(date).format("YYYY.MM.DD")}</span>
          <LocalizedLink language={language} to={"/media#news"}>
            <RollbackOutlined />
          </LocalizedLink>
        </div>
        <Divider />
        <NewsArticleContent content={html} />
      </section>
    </NewsArticleCSS>
  )
}

const NewsArticle = ({ data: { allMarkdownRemark }, location }) => {
  const { t, i18n } = useTranslation()
  const { node: { frontmatter: { title, tags, date, description }, html } } = allMarkdownRemark.edges[0]

  return (
    <Layout location={location} t={t} i18n={i18n}>
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <NewsArticleTemplate
        title={title}
        tags={tags}
        description={description}
        date={date}
        html={html}
        contentComponent={HTMLContent}
        location={location}
      />
    </Layout>
  )
}

NewsArticle.propTypes = {
  location: PropTypes.object.isRequired
}

export default NewsArticle

export const query = graphql`
    query ($language: String!, $id: String!) {
        locales: allLocale(filter: {ns: {in: ["media","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark (filter: { id: { eq: $id }}) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        tags
                    }
                    fields {
                        langKey
                        slug
                    }

                }
            }
        }
    }
`
